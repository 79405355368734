<template>
  <div class="m-3">
    <label class="form-label">Entreprise de sous-traitants et prestataires notifiés du ticket</label>
    <vue-good-table
      ref="companiesNotifiedTable"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
      :pagination-options="pagination"
      :totalRows="remoteData.totalRecords"
      :rows="remoteData.rows"
      :columns="columns"
      :sort-options="{
          enabled: true
        }"
    >
      <template v-slot:table-row="props">
        <div v-if="props.column.field === 'buildingTrades'" class="d-flex gap-3">
          <span v-for="(trade) in props.formattedRow[props.column.field]" :key="'trade-' + trade.id" class="badge bg-primary">{{ trade.value }}</span>
        </div>
      </template>
      <template v-slot:table-actions>
        <BuildingTradesFilter
          :selected="{ ...buildingTradesFilter }"
          @change="handleBuildingTradesFilter"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import backendApi from "@/backend/api";
import store from "@/store";
import BuildingTradesFilter from "@/components/BuildingTradesFilter";

export default {
  name: "InformedWorkersList",
  components: { BuildingTradesFilter },
  emits: ['selected:workers'],
  data: () => ({
    columns: [
      {
        label: 'Corps de métiers connus',
        field: 'buildingTrades',
      },
      {
        label: 'Compagnie',
        field: 'company.name',
      },
      {
        label: 'SIREN',
        field: 'company.registrationNumber',
      }
    ],
    pagination: {
      enabled: true,
      mode: 'records',
      perPage: 10,
      position: 'top',
      perPageDropdown: [10, 25, 50],
      dropdownAllowAll: false,
      setCurrentPage: 1,
      jumpFirstOrLast: true,
      firstLabel: 'Début',
      lastLabel: 'Fin',
      nextLabel: 'Suiv.',
      prevLabel: 'Préc.',
      rowsPerPageLabel: 'Lignes par page ',
      ofLabel: 'de',
      pageLabel: 'page',
      allLabel: 'Tous',
      // infoFn: (params) => `Page ${params.currentPage}`,
    },
    buildingTradesFilter: {
      buildingTrades: [],
      companies: [],
      registrationNumbers: []
    },
    isLoading: false,
    serverParams: {
      extraFilter: {},
      columnFilters: {},
      sort: [],
      page: 1,
      perPage: 10
    },
    remoteData: {
      rows: [],
      totalRecords: 0,
      workers: []
    }
  }),
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ sort: params })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true

      const params = JSON.parse(JSON.stringify(this.serverParams))

      params.extraFilter = {
        ...params.extraFilter,
        buildingTrades: this.buildingTradesFilter.buildingTrades.reduce((acc, item) => ([...acc, item.value]), []),
        companies: this.buildingTradesFilter.companies.reduce((acc, item) => ([...acc, item.name]), []),
        registrationNumbers: this.buildingTradesFilter.registrationNumbers.reduce((acc, item) => ([...acc, item.registrationNumber]), [])
      }

      backendApi
        .getWorkersAccount(store.state.login.user.token, params)
        .then(data => {
          this.remoteData.totalRecords = data.totalRecords
          this.remoteData.rows = data.rows
          this.remoteData.workers = data.workers
          this.updateParams({
            page: data.currentPage,
            perPage: data.maxResult,
          })
          this.$emit('selected:workers', this.remoteData.workers)
        })
    },
    handleBuildingTradesFilter(data) {
      this.buildingTradesFilter = { ...data }

      this.loadItems()
    }
  },
  mounted() {
    this.loadItems()
  }
}
</script>

<style scoped>
#output-selected-row {
  margin: 10px 0 10px 0;
}
</style>
